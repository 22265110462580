<template>
    <div>        
        <div class="container">
            <el-tabs v-model="activeName" >
                <el-tab-pane label="系统项目" name="系统项目">
            <el-form ref="form" label-position="left" :model="curForm" label-width="130px">    
                <el-form-item label="投放日期">
                        <el-date-picker
                            v-model="curForm.dateArray"
                            type="daterange"
                            range-separator="至"
                            value-format="yyyy/MM/dd HH:mm:ss"
                            start-placeholder="年/月/日"
                            end-placeholder="年/月/日">
                        </el-date-picker>
                    </el-form-item>
                    <el-row> 
                        <!-- style="width:605px;" collapse-tags-->
                        <el-form-item label="投放车站" style="width:342px;">
                            <el-cascader 
                                :show-all-levels="false"                                
                                placeholder="请选择投放车站"
                                :options="areaStationList"
                                :props="props"
                                v-model="curForm.stationIdArr"     
                                collapse-tags         
                                clearable   
                                ></el-cascader>
                        </el-form-item>
                        <el-form-item label="选择产品包"  style="margin-left:30px;width:342px;">
                            <el-select multiple="" v-model="curForm.productArr" placeholder="请选择产品包">
                                <el-option
                                    :props="props"
                                    v-for="item in productList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="投放品牌" >
                            <el-input v-model="curForm.brand" style="width:213px;" placeholder="投放品牌" class="handle-input mr10"></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="投放版本"  style="margin-left:30px;width:342px;">
                            <el-input v-model="curForm.adVersion" placeholder="投放版本" class="handle-input mr10"></el-input>
                        </el-form-item> -->
                    <!-- </el-row>
                    <el-row> -->
                        <el-form-item label="广告主行业" style="margin-left:30px;">
                                <el-select v-model="curForm.industryArr" multiple style="width:213px;" placeholder="请选择广告主行业">
                                    <el-option
                                    :props="props"
                                    v-for="item in categoryList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                        </el-form-item>

                        <el-button type="primary" style="margin-left:30px;height:20px;" icon="el-icon-search" @click="onQuery(0)">搜索</el-button>
                    </el-row>
                    <div style="display:flex;justify-content:flex-end;">
                        <!-- <el-button plain v-if="selectedList.length>1" type="primary" @click="onResult">综合效果</el-button> -->
                        <el-button plain v-if="selectedList.length>0" type="primary" @click="onQuery(1)">导出项目列表</el-button>
                    </div>
                    <el-table
                            :data="GetProjectSmartList"
                            border
                            ref="commonTable"
                            :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                            style="width: 100%;margin-top: 10px;"
                            :row-key="getRowKey" stripe 
		                    @selection-change="handleSelectionChange">
                            <el-table-column type="selection" reserve-selection width="55"></el-table-column>	
                            <el-table-column prop="projectName" align="center"  label="项目名称">
                                <template v-slot="scope">                                    
                                    <el-button  type="text" @click="onMediaDetail(scope.row,false)">{{scope.row.projectName}} </el-button>
                                    <!-- / {{scope.row.mediaCode}} -->
                                </template>
                            </el-table-column>					
                            <el-table-column prop="id" align="center" label="投放周期" width="121">
                                <template v-slot="scope">
                                    {{scope.row.sDate}} 至 {{scope.row.eDate}}
                                </template>
                            </el-table-column>
                            
                            <el-table-column prop="cateName" align="center"   label="行业"></el-table-column>
                            <el-table-column prop="brand" align="center"   label="品牌"></el-table-column>
                            <el-table-column prop="brand" align="center"   label="投放车站">
                                <template v-slot="scope">
                                    {{scope.row.shortStation}} 
                                    <!-- / {{scope.row.mediaCode}} -->
                                </template>
                            </el-table-column>
                            <el-table-column prop="ledDiscount" align="center"   width="80" label="折扣">
                                <template v-slot="scope">
                                    <div v-if="scope.row.ledDiscount && scope.row.ledDiscount!==10">{{scope.row.ledDiscount}} (LED)</div>
                                    <div v-if="scope.row.dxDiscount && scope.row.dxDiscount !==10">{{scope.row.dxDiscount}} (灯箱)</div>
                                    <div v-if="scope.row.lcDiscount && scope.row.lcDiscount !==10">{{scope.row.lcDiscount}} (列车)</div>
                                    <div v-if="scope.row.ledDiscount==10 && scope.row.dxDiscount ==10 && scope.row.lcDiscount ==10">无折扣</div>
                                    <!-- / {{scope.row.mediaCode}} -->
                                </template>
                            </el-table-column>
                            <!-- <el-table-column prop="dxDiscount" align="center"  sortable label="灯箱折扣"></el-table-column> -->
                            <el-table-column prop="cost" align="center"   width="80" sortable label="费用(万元)"></el-table-column>
                            <!-- <el-table-column prop="length" align="center" width="50"  label="长度"></el-table-column>
                            <el-table-column prop="times" align="center" width="60"  label="频次/每日"></el-table-column> -->
                            <!-- <el-table-column prop="frequency" align="center"   label="暴露频次(次)"></el-table-column>
                            <el-table-column prop="personCnt" align="center"   label="触达人口"></el-table-column> -->
                            <el-table-column prop="frequency" align="center"   label="累计接触度(千人次)"></el-table-column>
                            <el-table-column prop="personCnt" align="center"   width="80"  label="到达率(千人)"></el-table-column>
                            <el-table-column prop="cpm" align="center"  width="80"  label="CPM(元)"></el-table-column>
                            <el-table-column prop="Reach" align="center"  width="80"  label="暴露频次(次)"></el-table-column>
                            
                            <!-- <el-table-column prop="id" align="center"  width="80" label="投放版本" >
                                <template v-slot="scope">
									<el-button v-if="scope.row.verId" @click="onLookItemClick(scope.row)" type="text">查看</el-button>
									<el-tooltip content="上传"  effect="light">
                                        <i class="el-icon-upload el-icon--right" @click="onUploadItemClick(scope.row)"></i>
                                    </el-tooltip>
								</template>
                            </el-table-column> -->
                            <el-table-column prop="rowTime" align="center"   label="创建时间"></el-table-column>
                            <el-table-column prop="id" align="center" label="操作" width="100">
                                <template v-slot="scope">
                           
                                    <el-button type="text" @click="exportData(scope.row,1)">Excel报告</el-button>
                                    <el-button type="text" @click="exportData(scope.row,2)">PPT</el-button>
                                    

                                    <br>
                                    <el-tooltip content="删除"  effect="light">
                                        <i class="el-icon-delete" color="red" style="margin-top:5px;" @click="onRemove(scope.row)"></i>
                                    </el-tooltip>
                                    <el-tooltip content="编辑"  effect="light">
                                        <i class="el-icon-edit" style="margin-left:15px;margin-top:5px;" color="red" @click="onMediaDetail(scope.row,true)"></i>
                                    </el-tooltip>

                                </template>
                            </el-table-column>
                    </el-table>
            </el-form>
                </el-tab-pane>
                <el-tab-pane label="上传项目" name="上传项目">
                    <div style="display:flex;justify-content:flex-end;">
                        <el-button type="primary" style="margin-left:30px;height:20px;" icon="el-icon-plus" @click="newProjectDialogVisible=true">新建</el-button>
                    </div>
                    <el-form ref="scform" label-position="left" :model="scform" label-width="130px">
                        <el-form-item label="上传日期">
                            <el-date-picker
                                v-model="scform.dateArray"
                                type="daterange"
                                range-separator="至"
                                value-format="yyyy/MM/dd HH:mm:ss"
                                start-placeholder="年/月/日"
                                end-placeholder="年/月/日">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="关键字" >
                            <el-input v-model="scform.key" style="width:213px;" placeholder="输入关键字" class="handle-input mr10"></el-input>
                        </el-form-item>
                    </el-form>
                    <div style="display:flex;justify-content:center;">
                        <el-button type="primary" style="margin-left:30px;height:20px;" icon="el-icon-search" @click="onPrivateQuery()">搜索</el-button>
                    </div>

                    <el-table
                            :data="privateList"
                            border
                            ref="commonTable"
                            :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                            style="width: 100%;margin-top: 10px;"
                            :row-key="getRowKey" stripe 
		                    @selection-change="handleSelectionChange">
                            <el-table-column type="selection" reserve-selection width="55"></el-table-column>	
                            <el-table-column prop="projectName" align="center"  label="项目名称"></el-table-column>	
                            <el-table-column prop="description" align="center"  label="项目描述"></el-table-column>					
                            <el-table-column prop="id" align="center" label="文件" width="121">
                                <template v-slot="scope">
                                    <el-button type="text" @click="onDownItem(scope.row)">下载</el-button>
                                </template>
                            </el-table-column>                            
                            <el-table-column prop="rowTime" align="center"   label="创建时间"></el-table-column>
                            <el-table-column label="操作" align="center">
                                <template v-slot="scope">
                                    <el-button type="text" @click="onDeleteItem(scope.row)">删除</el-button>										
                                </template>
                            </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
            <upload-dialog ref="upDlg" v-model="uploadDialogVisible" @close="closeDlg" :project="currentProject"/>
            <ver-list-dialog v-model="verListDialogVisible"  @close="closeDlg" :project="currentProject"/>
            <result-dialog v-model="resultDialogVisible"  @close="closeDlg" :result="result"/>

            <el-dialog :title="title" v-model="setDetailDialogVisible" width="80%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body>
                    <!-- <div>  {{projectMediaList.length}}条                  -->
                        <div >投放总费用：<span style="color:red">{{curItem.cost}}</span>(万元)</div>
                        <div style="display:flex;">
                            <div >累计接触度:<span style="color:red">{{curItem.frequency}}</span>(千人次)</div>
                            <div style="margin-left:20px;">到达率：<span style="color:red">{{curItem.personCnt}}</span>(千人)</div>
                            <div style="margin-left:20px;">暴露频次：<span style="color:red">{{curItem.Reach}}</span>(次)</div>
                            <div style="margin-left:20px;">CPM：<span style="color:red">{{curItem.cpm}}</span>(元)</div>
                        </div>
                        <el-form v-if="canEdit"  ref="form" :model="searchForm" label-width="80px" style="margin-top:20px;" label-position="left">
                            <el-row>
                                <el-col :span="20">
                                    <span v-if="curItem.haveLed"> LED 
                                        <!-- <span style="color:red;margin-left:2px;">{{curItem.ledDiscount}}</span>折  @blur="onSlideChange('1')" -->
                                        <el-input-number style="width: 135px;margin-top:5px;" v-model="curItem.ledDiscount" :precision="1" :step="0.1" :min="0" :max="10" @change="onSlideChange('1')" ></el-input-number>
                                    </span>
                                <!-- </el-col>
                                <el-col :span="10">                                 -->
                                    <span v-if="curItem.haveDx || curItem.haveDigitalDx" style="margin-left:20px;"> 灯箱
                                        <!-- <span style="color:red;margin-left:2px;">{{curItem.dxDiscount}}</span>折  @change="onSlideChange('2')"-->
                                        <el-input-number style="width: 135px;margin-top:5px;" v-model="curItem.dxDiscount" :precision="1" :step="0.1" :min="0" :max="10" @change="onSlideChange('2')" ></el-input-number>
                                    </span> 
                                </el-col>
                                <el-col>
                                    <el-button style="float: right; padding: 3px 0;margin-left:20px;" type="text" @click="onBatchOpen">统一设置</el-button>
                                    <el-button style="float: right; padding: 3px 0;margin-left:20px;" type="text" @click="onAdd">添加资源</el-button>
                                </el-col>
                            </el-row>
                        </el-form>

                        <!-- :data="detailData.slice((currentPage-1)*pageSize,currentPage*pageSize)" -->
                        <!-- @selection-change="handleMediaSelectionChange" -->
                        <el-input  v-model="searchkey" style="width:600px;" placeholder="可输入车站关键字进行检索"></el-input>
                        <el-table                        
                            
                            :data="detailData.filter(data => !searchkey || data.station.toLowerCase().includes(searchkey.toLowerCase())).slice((currentPage-1)*pageSize,currentPage*pageSize)"                           
                            :row-key="getDetailRowKey" stripe 
                            ref="filterDetailTable"
                            border
                            :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                            style="width: 100%;margin-top: 10px;font-size: x-small;">		
                            <el-table-column v-if="!canEdit" prop="productName" align="center" sortable label="产品包"></el-table-column>
                            <el-table-column prop="ydarea" align="center" width="80" sortable label="大区"></el-table-column>
                            <el-table-column prop="station" align="center" width="130" sortable label="车站"></el-table-column>
                            <!-- <el-table-column prop="station" label="车站" sortable width="150" column-key="station"
                                :filters="stationList"
                                :filter-method="filterHandler">
                            </el-table-column> -->
                            <el-table-column prop="name" align="center" sortable label="套餐名称"></el-table-column>                                         
                            <el-table-column prop="typeName" align="center" width="70" sortable label="媒体形式"></el-table-column>
                            <el-table-column prop="position" align="center" sortable label="位置"></el-table-column>
                            <el-table-column prop="totalCount" align="center"  width="50" sortable label="数量"></el-table-column>
                            <!-- <el-table-column v-if="!canEdit || groupLevel=='2'" prop="playDate" align="center" sortable label="投放周期"></el-table-column> -->
                            <el-table-column  prop="dateStr" align="center" label="投放周期" width="320">
								<template v-slot="scope" >
                                    <!-- <div v-if="!canEdit || groupLevel=='2'">{{scope.row.playDate}}</div> -->
                                    <!-- <div>{{canEdit}}--{{scope.row.groupLevel}}</div> -->
									<el-date-picker v-if="canEdit && scope.row.groupLevel=='1'"									
										v-model="scope.row.dateArray"
										@change="onDateChange($event,scope.row)"
										type="daterange"
										style="border:0px;"
										range-separator="至"
										:clearable="false"
										value-format="yyyy-MM-dd"
										start-placeholder="年/月/日"
										end-placeholder="年/月/日">
									</el-date-picker>
                                    <div v-else>{{scope.row.playDate}}</div>
								</template>
						    </el-table-column>

                            <el-table-column prop="length" align="center"  width="90" label="版本长度">
                                <template v-slot="scope" >
                                    <span v-if="scope.row.typeId=='1'">
                                        <span v-if="canEdit && scope.row.groupLevel=='1'">
                                            <el-select @change="lengthChanged($event,scope.row)" v-model="scope.row.length"  placeholder="请选择版本长度">
                                                <el-option
                                                    v-for="item in lengthList"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </span>
                                        <span v-else>{{scope.row.length}}</span>
                                    </span>
                                    <span v-else-if="scope.row.groupLevel=='2'">{{scope.row.length}}</span>
                                    <span v-else>--</span>                                  
                                </template>
                            </el-table-column>
                            <el-table-column prop="times" align="center"  width="130" label="每日投放次数">
                                <template v-slot="scope" >
                                    <span v-if="scope.row.typeId=='1'">
                                        <span v-if="canEdit && scope.row.groupLevel=='1'">
                                            <el-select @change="timesChanged($event,scope.row)" v-model="scope.row.times"  placeholder="请选择每日投放次数">
                                                <el-option
                                                    v-for="item in timesList"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </span>
                                        <span v-else>{{scope.row.times}}</span>
                                    </span>
                                    <span v-else-if="scope.row.groupLevel=='2'">{{scope.row.times}}</span>
                                    <span v-else>--</span>                                
                                </template>
                            </el-table-column> 
                            <el-table-column prop="totalPrice" align="center" width="70" sortable label="刊例(万元)"></el-table-column>
                            <el-table-column v-if="canEdit" prop="id" align="center" label="操作" width="60">
                                <template v-slot="scope">
                                     <el-button  type="text"  @click="onRemoveMedia(scope.$index,scope.row)">移除</el-button>
                                     <!-- <el-button v-if="scope.row.groupLevel=='2'" type="text"  @click="onModifyProductMedia(scope.row)">编辑资源</el-button> -->
                                </template>
                            </el-table-column>

                        </el-table>
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="[20,50, 100]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="detailData.length">
                        </el-pagination>
                    <!-- </div> -->
                    <div class="button-footer;" style="display:flex;justify-content:flex-end;">                      
                        <span  class="dialog-footer" >
                            <el-button v-if="canEdit" type="primary" @click="onSaveProject">保存</el-button>
                            <el-button @click="onCloseDetail">关闭</el-button>
                            
                        </span>                    
                    </div>
            </el-dialog>

            <el-dialog title="选择资源" v-model="chooseMediaDialogVisible" width="60%" 
                    :modal-append-to-body='false'
                    :close-on-click-modal='false'
                    @close='closeAddDialog'
                    append-to-body>
                    <select-media-package ref="selectMediaPackageObj" :group-id="0" :except-ids="currentUseIds" @change="changeItem"/>
            </el-dialog>

            <el-dialog title="统一设置" v-model="chooseBatchSetDialogVisible" width="50%" 
                    :modal-append-to-body='false'
                    :close-on-click-modal='false'
                    append-to-body>
                    <el-form ref="form" :model="batchForm" label-width="131px" style="margin-top:20px;" label-position="left">
                        <el-row>
                            <el-form-item label="投放时期">
                                <el-date-picker 								
                                    v-model="batchForm.dateArray"                                    
                                    type="daterange"
                                    style="border:0px;"
                                    range-separator="至"
                                    :clearable="false"
                                    value-format="yyyy-MM-dd"
                                    start-placeholder="年/月/日"
                                    end-placeholder="年/月/日">
                                </el-date-picker>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="版本长度">
                                <el-select v-model="batchForm.length"  placeholder="请选择版本长度">
                                    <el-option
                                        v-for="item in lengthList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-row>  
                        <el-row>
                            <el-form-item label="每日投放次数">
                                <el-select v-model="batchForm.times"  placeholder="请选择每日投放次数">
                                    <el-option
                                        v-for="item in timesList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-row> 
                    </el-form> 
                    <div style="display:flex;justify-content:center;margin-top:20px;">
                        <el-button type="primary" @click="onBatch">确定</el-button>
                        <el-button @click="chooseBatchSetDialogVisible=false">取消</el-button>
                    </div>
            </el-dialog>

            <el-dialog title="编辑产品资源" v-model="modifyProductMediaVisible" width="60%" 
                    :modal-append-to-body='false'
                    :close-on-click-modal='false'
                    @close='closeAddDialog'
                    append-to-body>
                    <el-table
                                :data="productMediaList.slice((ppml.currentPage-1)*ppml.pageSize,ppml.currentPage*ppml.pageSize)"                            
                                border
                                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                                style="width: 100%;margin-top: 10px;font-size: x-small;">		
                                <el-table-column prop="area" align="center"  width="80"  sortable label="大区"></el-table-column>
                                <el-table-column prop="province" align="center"  width="60"  sortable label="省份"></el-table-column>
                                <el-table-column prop="station" align="center"   width="70" sortable label="车站"></el-table-column>	                            
                                <el-table-column prop="position" align="center"   width="80" sortable label="位置"></el-table-column>
                                <el-table-column prop="typeName" align="center"   width="70" sortable label="资源类型"></el-table-column>
                                <el-table-column prop="name" align="center"    sortable label="套餐名"></el-table-column>
                                <!-- <el-table-column prop="totalCount" align="center"  width="50"  label="资源数量"></el-table-column> -->
                                
                                <el-table-column v-if="curProduct.isLimit==0" prop="medias" align="center"   width="120" sortable label="新套餐名称">
                                    <template v-slot="scope">
                                            <el-button  class="product-name" type="text" @click="onOpenAdjust(scope.row)">{{scope.row.newName}}</el-button>
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column prop="remark" align="center"  sortable label="说明"></el-table-column> -->
                                
                                <!-- <el-table-column prop="id" align="center" label="操作" width="90">
                                    <template v-slot="scope">
                                        <div style="display:flex;">
                                            <el-button style="float: right; padding: 3px 0" type="text" @click="onSetCondition(scope.row)">加入购物车</el-button>
                                        </div>
                                    </template>
                                </el-table-column> -->
                            </el-table>  
                            <el-pagination
                                        @size-change="handleppmlSizeChange"
                                        @current-change="handleppmlCurrentChange"
                                        :current-page="ppml.currentPage"
                                        :page-sizes="[20,50, 100]"
                                        :page-size="ppml.pageSize"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="productMediaList.length">
                            </el-pagination>   
                    <!-- <select-product ref="selectMediaPackageObj" :group-id="0" :except-ids="currentUseIds" @change="changeItem"/> -->
            </el-dialog>

            <el-dialog title="PPT设置" v-model="setPPTDialogVisible" width="60%" 
                    :modal-append-to-body='false'
                    :close-on-click-modal='false'                    
                    append-to-body>
                <div style="font-weight:bold;">PPT结构</div>
                <div style="margin-top:8px;margin-left:8px;">
                    <el-checkbox-group 
                        v-model="pptForm.checkedItems"
                        @change="onChangePPTItem"                    
                        >
                        <el-checkbox v-for="item in CanSelectItems" :label="item" :key="item">{{item}}</el-checkbox>
                    </el-checkbox-group>
                </div>
                <div v-if="selectJinju" style="margin-top:20px;">
                    <div style="font-weight:bold;">选择金句</div>
                    <div v-for="item in goldenList" :key="item" style="margin-top:8px;margin-left:8px;">
                        <el-tag><span style="text-decoration:underline;">{{item.label}}</span></el-tag>
                        <div>
                            <el-checkbox-group v-model="pptForm.checkedGoldenItems">
                                <el-checkbox v-for="subitem in item.children" :label="subitem" :key="subitem.value">{{subitem.label}}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>                    
                </div>
                <div style="display:flex;justify-content:center;margin-top:20px;">
                    <el-button type="primary" @click="onCreatePPT">确定</el-button>
                    <el-button @click="setPPTDialogVisible=false">取消</el-button>
                </div>
            </el-dialog>

            <el-dialog title="新建项目报告" v-model="newProjectDialogVisible" width="40%" 
                    :modal-append-to-body='false'
                    :close-on-click-modal='false'                    
                    append-to-body>
                <el-form :model="form">
					<el-form-item label="项目名称">
						<el-input v-model="form.projectName" style="width:213px;" placeholder="输入项目名称" class="handle-input mr10"></el-input>
					</el-form-item>
                    <el-form-item label="说　　明" style="width: 100%;">
                        <el-input v-model="form.description" type="textarea" autocomplete="off" :rows="3"
                                                                style="width: 615px;"></el-input>
                    </el-form-item>
					<my-upload-file-form-item label="项目文件" :limit="1" @change="getFileArr" v-model="form.fileArray"/>
				</el-form>
                
                <div style="display:flex;justify-content:center;margin-top:20px;">
                    <el-button type="primary" @click="onSavePrivate">确定</el-button>
                    <el-button @click="newProjectDialogVisible=false">取消</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {getLatestMonthDateArrayByDate,getDateArrayByBoLangXian,getNextMonthDateArray,getLatestThreeMonthDateArray} from "@/utils/time";
import UploadDialog from "@/views/Business/Project/UploadDialog";
import VerListDialog from "@/views/Business/Project/VerListDialog";
import ResultDialog from "@/views/Business/Project/ResultDialog";
import {getToken} from "@/utils/auth";
import {DelProjectSmart,ProjectExportById,SaveProjectSmart,ProjectListExport} from "@/api/operate";
import SelectMediaPackage from './SelectMediaPackage.vue';
import MyUploadFileFormItem from "@/components/MyUploadFileFormItem";
// import SelectProduct from './SelectProduct.vue';
import {SavePrivateProject,DelPrivateProject} from "@/api/privateProject";
import {getFileUrl} from "@/api/file";

export default {
    name: 'baseform',
    components:{
        UploadDialog
        ,VerListDialog
        ,ResultDialog
        ,SelectMediaPackage
        ,MyUploadFileFormItem
        // ,SelectProduct
    },
    computed: {...mapGetters(['GetProjectSmartList','areaStationList','categoryList','productList'
        ,'GetGroupandSimpleMedia','projectMediaList','projectMediaList_NotDetail','GetPlanPrice','CalculateMultiSmart'
        ,'GetProjectProductMediaList','goldenList','privateProjectList'])},
    data() {
        return {
            chooseMediaDialogVisible:false,
            canEdit:false,
            setDetailDialogVisible:false,
            modifyProductMediaVisible:false,
            title:'',
            curItem:{},
            curProduct:{},
            detailData:[],
            productMediaList:[],
            currentUseIds:'',
            lastAddItem:{},     //保存上一次添加资源的信息，用于对再次新加资源时赋默认值：起止日期、版本长度、每日播放次数
            // setEditDialogVisible:false,
            
             // 当前页
            currentPage: 1,
            // 每页多少条
            pageSize: 20,
            ppml:{
                currentPage: 1,
                pageSize: 20,
            },
            batchForm:{
                dateArray:[],
                times:'',
                length:'',
            },

            curForm: {    
                dateArray:getNextMonthDateArray(),
                brand:'',    
                industryArr:[],         
                stationIdArr:[],
                // mediaId:'',
                productArr:[],
                saleUserId:'',
                adVersion:'',
            },
            props: { multiple: false },
            singleStation:true,
            uploadDialogVisible: false,
            verListDialogVisible:false,
            resultDialogVisible:false,
            currentProject:{},
            result:{frequency:0,cost:0,CPM:0},
            selectedList:[],
            
            lengthList:[{label:'10秒',value:'10'},{label:'15秒',value:'15'}],
            timesList:[{label:'100次',value:100},{label:'200次',value:200},{label:'300次',value:300},{label:'400次',value:400},
            {label:'500次',value:500},{label:'600次',value:600},{label:'700次',value:700}],

            setPPTDialogVisible:false,
            pptForm:{
                checkedItems:['项目名称'],
                checkedGoldenItems:[],
            },            
            CanSelectItems:['行业动态','广告市场','高铁传播优势','分析指标','车站信息','资源信息','金句','关于我们'],
            selectJinju:false,
            curProjectId:'',

            activeName: '系统项目'  ,
            scform:{
                // dateArray:getNextMonthDateArray(),
                dateArray:getLatestThreeMonthDateArray(),
                key:'',
            },
            flagId:1,
            privateList:[],
            form: {projectName: '', fileArray: [],description:''},
            newProjectDialogVisible:false,
            chooseBatchSetDialogVisible:false,
            // stationList:[],
            searchkey:'',
        };
    },
    
    async mounted() {
        await this.$store.dispatch('operate/areaStationList');
        await this.$store.dispatch('operate/categoryList');
        await this.$store.dispatch('operate/productList');    
        await this.$store.dispatch('operate/goldenList');
        console.log('goldenList',this.goldenList)
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = 'http://pv.sohu.com/cityjson?ie=utf-8&ver='+Math.random();
        document.body.appendChild(s);

        this.onQuery(0);
        this.onPrivateQuery();
    },

    // watch:{
    //     curItem.ledDiscount:
    // }

    methods: {
       
      async onQuery(isExport){
            const [sYM, eYM] = this.curForm.dateArray;
            
            var sDate = new Date(sYM).toLocaleDateString();
            var eDate = new Date(eYM).toLocaleDateString();

            console.log('sDate',this.curForm)

            const {brand,industryArr,stationIdArr,productArr,saleUserId,adVersion} = this.curForm;

            var stationIds= '';
            if (stationIdArr?.length>0){
                stationIds = stationIdArr[2];//this.getStatitionId(stationIdArr);
            }
            // console.log('sDate',stationIds)
            var industryIds="";
            if (industryArr){
                industryIds=industryArr.join(',');
            }
            var productIds="";
            if (productArr){
                productIds=productArr.join(',');
            }
            if (isExport==0){
                this.selectedList=[];
                this.$refs.commonTable.clearSelection();
                await this.$store.dispatch('operate/GetProjectSmartList', {sDate,eDate,brand,industryIds,stationIds,productIds,saleUserId,adVersion});
            }else{
                var projectIds='';
                this.selectedList.forEach(ele => {
                    if (projectIds==''){
                        projectIds=ele.projectId+'';
                    }else{
                        projectIds=projectIds+','+ele.projectId;
                    }
                });
                await ProjectListExport({userId: getToken(),projectIds}).then(()=>{
                    // loading.close();
                })
                // await this.$store.dispatch('operate/GetProjectSmartList', {sDate,eDate,brand,industryIds,stationIds,productIds,saleUserId,adVersion});
            }
            
      },

    //   filterHandler(value, row, column) {
    //             // this.selectedMediaList=[];
    //             // this.$refs.filterTable.clearSelection(); 
    //             const property = column['property'];
    //             return row[property] === value;
    // },

      async onPrivateQuery(){
        const [sYM, eYM] = this.scform.dateArray;            
        var sDate = new Date(sYM).toLocaleDateString();
        var eDate = new Date(eYM).toLocaleDateString();
        await this.$store.dispatch('privateProject/privateProjectList', {sDate,eDate,key:this.scform.key,isPublic:0,fileTypeId:0}).then((res)=>{
            console.log('aa',this.privateProjectList)
            this.privateList = this.privateProjectList;
        })
      },

      onDownItem(item) {
					// console.log('aa',item,getFileUrl({name: item.sample}));
		document.location.href = getFileUrl({name: item.files})
	  },

      async onDeleteItem(item) {
        // console.log(item)
        this.$confirm('确认删除'+item.projectName+', 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(async () => {
            await DelPrivateProject({userId: getToken(), privateProjectId: item.privateProjectId});
            // await this.$store.dispatch('operate/GetAdVersionList', {projectId:this.project.projectId});
            this.onPrivateQuery();
            this.$message({
                type: 'success',
                message: '删除成功!'
            });
        }).catch(() => {
            // this.$message({
            //     type: 'info',
            //     message: '已取消删除'
            // });          
        });

            
      },

      getDetailRowKey(row){
        return row.detailId;
      },

      getStationList(){
        // this.stationList=[];
        // this.detailData.forEach(ele => {
        //     var stat = ele.station;
        //     let index = this.stationList.findIndex(val => val.value === stat);
        //     if (index == -1){
        //         this.stationList.push({text: stat, value: stat});
        //     }
        // });
      },
    


      async onSavePrivate(){
        // console.log(this.form);
		const {projectName, fileArray: [files],description} = this.form;
		if (!projectName || !files) {
			this.$message.error('请填写完整');
			return;
		}
		await SavePrivateProject({
								userId: getToken(),
								privateProjectId: '',
                                projectName,
                                description,
								files:files.name,	
                                isPublic:0,
                                fileTypeId:0				
		});
		this.$message.success('保存成功');
		this.newProjectDialogVisible=false;
		// await this.$store.dispatch('operate/GetProjectSmartList',);
        this.onPrivateQuery();
      },

      getFileArr(item){
            if (item.length || item.length==0){
                this.form.fileArray=item;
            }else{
                // console.log('bb')
            }
            console.log('item111',item,item.length)
     },

      async onMediaDetail(row,flag){
          this.canEdit=flag;
          console.log('row',row,flag)
          this.curItem=JSON.parse(JSON.stringify(row));

          if (flag==true){
              this.title='《'+row.projectName + "》编辑投放方案"
              await this.$store.dispatch('operate/projectMediaList_NotDetail',{projectId:row.projectId}).then(()=>{
                this.detailData = this.projectMediaList_NotDetail.map(item=>({...item,dateArray:getDateArrayByBoLangXian(item.playDate)}));
                this.getStationList();
                this.setDetailDialogVisible=true;
            })
          }else{
              this.title = '《'+row.projectName + "》查看投放资源"
                await this.$store.dispatch('operate/projectMediaList',{projectId:row.projectId}).then(()=>{
                this.detailData = this.projectMediaList.map(item=>({...item,dateArray:getDateArrayByBoLangXian(item.playDate)}));
                this.setDetailDialogVisible=true;
            })
          }
          
          
          this.lastAddItem={};
          this.detailData.forEach(ele => {
              if (ele.typeId=="1"){
                  this.lastAddItem.sDate=ele.sDate;
                  this.lastAddItem.eDate=ele.eDate;
                  this.lastAddItem.length=ele.length;
                  this.lastAddItem.times=ele.times;
              }
          });
        //   console.log('bb',this.lastAddItem,)
          if (Object.keys(this.lastAddItem).length==0){
            this.lastAddItem.sDate=this.detailData[0].sDate;
            this.lastAddItem.eDate=this.detailData[0].eDate;
            this.lastAddItem.length=15;
            this.lastAddItem.times=200;
          }
        //   if (!this.lastAddItem.sDate){
        //     //   console.log('aaa',getNextMonthDateArray())
        //       var dt = getNextMonthDateArray();
        //       this.lastAddItem.sDate = dt[0];
        //       this.lastAddItem.eDate = dt[1];
        //     //   console.log('sDate',dt)
        //   }
        //   console.log('aaaa',this.lastAddItem)
          
      },

      async onSlideChange(type){  
            Promise.all(this.detailData.map((ele)=>{
				return new Promise(async (resolve, reject)=>{
                    // console.log('aaa',type,ele.typeId)
                        if ((type=='1' && ele.typeId=='1') || (type=='2' && (ele.typeId=='2' || ele.typeId=='3'))  && ele.groupLevel=='1'){
                            const {sDate,eDate,length,times,groupId,totalPrice}=ele;
                            // console.log('aaa')
                            if (totalPrice>0){
                                await this.$store.dispatch('operate/GetPlanPrice',{sDate,eDate,length,times,ledDisCount:this.curItem.ledDiscount,dxDisCount:this.curItem.dxDiscount,groupId}).then(()=>{
                                    ele.totalPrice = parseFloat(this.GetPlanPrice[0].Price).toFixed(2);
                                    // console.log('getprice',type,ele.name,ele.totalPrice)
                                })    
                            }
                        }  
                    
                    resolve();
				})
			})).then((data)=>{
                this.getTotalPrice();
                this.onCalculate();   
            });

            
        },


      async	onDateChange(event, item) {
		// console.log('changedate',event, item);
        const {dateArray:[tmpsDate,tmpeDate],length,times,groupId,totalPrice}=item;
        var sDate,eDate;
        if (tmpsDate.length>10){
            sDate=tmpsDate.getFullYear() + '-' + (tmpsDate.getMonth() + 1) + '-' + tmpsDate.getDate();//sDate;
            eDate = tmpeDate.getFullYear() + '-' + (tmpeDate.getMonth() + 1) + '-' + tmpeDate.getDate();//eDate;    
        }else{
            sDate=tmpsDate;
            eDate=tmpeDate;
        }                     

        this.lastAddItem.sDate=sDate;
        this.lastAddItem.eDate=eDate;
        this.lastAddItem.length=length;
        this.lastAddItem.times=times;

        if (totalPrice>0){
            Promise.all(this.detailData.map((ele)=>{
                return new Promise(async (resolve, reject)=>{
                    if (ele.detailId == item.detailId && ele.groupLevel=='1'){
                        await this.$store.dispatch('operate/GetPlanPrice',{sDate,eDate,length,times,ledDisCount:this.curItem.ledDiscount,dxDisCount:this.curItem.dxDiscount,groupId}).then(()=>{
                            // console.log('aa',item.totalPrice,this.GetPlanPrice)
                            item.totalPrice = parseFloat(this.GetPlanPrice[0].Price).toFixed(2);
                            item.sDate=sDate;
                            item.eDate=eDate;
                      
                            // console.log('getprice',type,ele.name,ele.totalPrice)
                        })   
                    }
                    resolve();
                })
            })).then((data)=>{
                this.getTotalPrice();
                this.onCalculate();   
            });             
        }
      },

      async lengthChanged(event, item){
          this.onDateChange(event,item);
      },

      async timesChanged(event, item){
          this.onDateChange(event,item);
      },

      async onModifyProductMedia(item){
            this.curProduct = item;
            await this.$store.dispatch('operate/GetProjectProductMediaList',{detailId:item.detailId}).then(()=>{
                this.productMediaList =this.GetProjectProductMediaList;
                // console.log('aa',this.curItem)
                this.modifyProductMediaVisible=true;
            })
          
      },

      onOpenAdjust(){

      },
        
        //计算总价格
        getTotalPrice(){
            var level1Cost=0;
            this.detailData.forEach(ele => {   
                // if (ele.groupLevel=='1')   {
                    level1Cost=Number(level1Cost)+Number(ele.totalPrice);
                // }                 
            });
            this.curItem.cost=parseFloat(Number(level1Cost)).toFixed(2);//+Number(this.curItem.level2Cost)
        },

        async onCalculate(){
            //var Ip=returnCitySN['cip']
            //var cityname=returnCitySN['cname']
			var Ip = '';
            var cityname = '';
            try {
                Ip = returnCitySN['cip'];
                cityname = returnCitySN['cname']
            } catch (e) {
            // 错误处理代码片段
            }
			
            localStorage.setItem('Ip', Ip)
            localStorage.setItem('cityName', cityname)

            const loading = this.$loading({
                lock: true,
                text: '计算中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            // await this.getNewDetail().then(async (data)=>{
                // console.log('data',data)
                var condi ;
                condi = JSON.stringify(this.detailData);

                console.log('condi',condi)
                var ip = localStorage.getItem('Ip');
                var cityName = localStorage.getItem('cityName');
                
                // await this.$store.dispatch('operate/CalculateMultiSmart',{ip,cityName,condi}).then(res=>{
                await this.$store.dispatch('operate/CalculateMultiSmart',{ip,cityName,budget:this.curItem.cost,ledDiscount:this.curItem.ledDiscount,dxDiscount:this.curItem.ledDiscount,condi}).then(res=>{
                    loading.close();
                    this.curItem.frequency=this.CalculateMultiSmart.Frequency;
                    this.curItem.personCnt=this.CalculateMultiSmart.chudarenshu;
                    this.curItem.Reach=this.CalculateMultiSmart.Reach;
                    this.curItem.cpm=this.CalculateMultiSmart.CPM;
                });
            // }) 
        },

        async onSaveProject(){
            const {ledDiscount,dxDiscount} = this.curItem;
           
            // await this.getNewDetail().then(async (data)=>{
                // console.log('aa',data)
            if (this.detailData.length==0){
                this.$message({message: '无选中资源，无法保存',type: 'error'});
                return;
            }
                var condi = JSON.stringify(this.detailData);
                    await SaveProjectSmart({userId:getToken(),
                                   projectId:this.curItem.projectId,
                                   projectName:this.curItem.projectName,
                                   cateId:this.curItem.cateId,
                                   brand:this.curItem.brand,
                                   budget:this.curItem.cost,
                                   cpm:this.CalculateMultiSmart.CPM,
                                   frequency:this.CalculateMultiSmart.Frequency,
                                   personCnt:this.CalculateMultiSmart.chudarenshu,
                                   reachRate:this.CalculateMultiSmart.Reach,
                                   ledDiscount:ledDiscount,
                                   dxDiscount:dxDiscount,
                                   condi}).then((data)=>{
                                   });
                    this.$message({
                        type: 'success',
                        message: '项目保存成功'
                    }); 
                this.onQuery(0);
                this.setDetailDialogVisible=false; 
            // });
                    
      
        },
           
        onAdd(){
            this.currentUseIds='';
            this.detailData.forEach(ele => {
                if (this.currentUseIds==''){
                    this.currentUseIds=ele.groupId;
                }else{
                    this.currentUseIds=this.currentUseIds+','+ele.groupId;
                }
            });
            this.chooseMediaDialogVisible=true;
        },

        onBatchOpen(){
            if (this.detailData.length>0){
                this.batchForm.dateArray=[this.detailData[0].sDate,this.detailData[0].eDate];
                this.batchForm.times=this.detailData[0].times;
                this.batchForm.length=this.detailData[0].length;
            }
            
            this.chooseBatchSetDialogVisible=true;
        },

        onBatch(){
            const{dateArray:[sDate,eDate],times,length}=this.batchForm;
            this.detailData.forEach(ele => {
                ele.sDate=sDate;
                ele.eDate=eDate;
                ele.times=times;
                ele.length=length;
                ele.dateArray = [sDate,eDate];
                ele.playDate = sDate+"~"+eDate;
            });
            this.onSlideChange('1').then(()=>{
                this.chooseBatchSetDialogVisible=false;
            })
        },

        onRemoveMedia(index,item){
            console.log(index)
            this.$confirm('确认删除 '+item.station+' '+item.name+', 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
            }).then(async () => {
                this.detailData.splice(index,1);
                this.getStationList();
                this.setMediaType();
                this.getTotalPrice(); 
                this.onCalculate();    
                        // await DelProjectSmart({userId: getToken(), projectId: item.projectId});
                        
                        // this.$message({
                        //     type: 'success',
                        //     message: '删除成功!'
                        // });
                        // this.onQuery();
            }).catch(() => {
                        // this.$message({
                        //     type: 'info',
                        //     message: '已取消删除'
                        // });          
            });
        },

        async changeItem(item){
            console.log('changeItem',item)
            // this.$refs.selectMediaPackageObj.clearSearchList();
            // this.detailData.forEach(ele => {
            //     if (ele.groupId==this.curChangeGroupId){                    
            //         if (!ele.newGroupId){
            //             this.adjustCost = Number(this.adjustCost)+Number(item.cost);
            //             console.log('this.adjustCost',this.adjustCost)
            //         }
            //         ele.newGroupId = item.groupId;
            //         ele.newGroupLabel = item.name;
            //     }
            // });
            // this.curItem.haveSelectList = this.groupDetailMediaList;
            // this.haveSelectCnt = this.curItem.haveSelectList.length;
            // this.chooseMediaDialogVisible=false;
            var obj={};
            const {sDate,eDate,length,times} = this.lastAddItem;
            const {area,station,name,typeName,typeIds,position,totalCount} = item;
            obj.detailId=-1;
            obj.productName='/';
            obj.ydarea=area;
            obj.station=station;
            obj.name=name;
            obj.typeName=typeName;
            obj.typeId=typeIds;
            obj.position=position;
            obj.totalCount=totalCount;
            obj.sDate=sDate;
            obj.eDate=eDate;
            obj.dateArray=[sDate,eDate];
            obj.groupId=item.groupId;
            obj.groupLevel=1;
            obj.length=length;
            obj.times=times;
            obj.haveSelectListIds='';
            obj.adjLog='';
            await this.$store.dispatch('operate/GetPlanPrice',{sDate,eDate,length,times,ledDisCount:this.curItem.ledDiscount,dxDisCount:this.curItem.dxDiscount,groupId:item.groupId}).then(()=>{
                obj.totalPrice = parseFloat(this.GetPlanPrice[0].Price).toFixed(2);
                this.detailData.push(obj);
                console.log('changeItem',this.detailData)
                this.getStationList();
                this.setMediaType();
                this.getTotalPrice();
                this.onCalculate();   
                this.chooseMediaDialogVisible=false;
            })   
        },

        setMediaType(){
            this.curItem.haveLed=false;
            this.curItem.haveDx=false;
            this.detailData.forEach(ele => {
                if (ele.groupLevel=='1' && ele.totalPrice>0 && ele.typeId=='1'){
                    this.curItem.haveLed=true;
                }
                if (ele.groupLevel=='1' && ele.totalPrice>0 && (ele.typeId=='2' || ele.typeId=='3')){
                    this.curItem.haveDx=true;
                }
            });
        },

        onNew(){

        },

      getRowKey(row) {
		return row.projectId;
	  },

      handleSelectionChange(val) {				
		this.selectedList = val;				
		// this.SelectEdEmit();
	},

    // 每页多少条
    handleSizeChange(val) {
        this.pageSize = val;
    },
    handleppmlSizeChange(val) {
        this.ppml.pageSize = val;
    },
    // 当前页
    handleCurrentChange(val) {
        this.currentPage = val;
    },
    handleppmlCurrentChange(val) {
        this.ppml.currentPage = val;
    },


        getStatitionId(areaArray){
            var stationIds="";
            areaArray.forEach(element => {
                stationIds = stationIds+element[2]+','
            });
            if (stationIds!=""){
                stationIds = stationIds.substring(0,stationIds.length - 1);
            }
            return stationIds;
        },

        onUploadItemClick(item) {
			// console.log(item);
			this.currentProject = item;
            this.currentProject.versionId=0;
            this.$refs.upDlg.init();
			this.uploadDialogVisible = true;
		},

        async onLookItemClick(item) {
			console.log('item',item);
			this.currentProject = item;
            await this.$store.dispatch('operate/GetAdVersionList', {projectId:item.projectId});
			this.verListDialogVisible = true;
		},

        onChangePPTItem(){
            if (this.pptForm.checkedItems.findIndex(item => item=='金句')>-1){
                this.selectJinju=true;
            }else{
                this.selectJinju=false;
            }
        },

        async onCreatePPT(){
            this.$confirm('生成PPT将耗费较长时间，确认是否制作PPT？ ', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {    
                const {checkedItems,checkedGoldenItems}=this.pptForm;
                // console.log(this.pptForm);
                // return;
                var items='';
                var golds='';
                if (checkedItems?.length>0){
                    items = checkedItems.join(',');
                    if (this.selectJinju){
                        if (checkedGoldenItems.length==0){
                            this.$message.success('请选择金句');
                            return;
                        }else{
                            checkedGoldenItems.forEach(ele => {
                                if (golds==""){
                                    golds = ele.value+'';
                                }else{
                                    golds = golds+','+ele.value;
                                }
                            });
                        }
                    }
                }else{
                    this.$message.success('请选择PPT结构');
                    return;
                }

                const loading = this.$loading({
                    lock: true,
                    text: '疯狂制作中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                            
                // window.location = 'http://adeffect.fusewise-info.com/AdEffectService/UploadBCSFiles/Report/投放方案PPT报告.pptx';
                // loading.close();
                
                await ProjectExportById({userId: getToken(),flagId:this.flagId,projectId:this.curProjectId,items,golds}).then(()=>{
                    loading.close();
                })                
                
            })

            
            
        },

        async exportData(item,flagId){
            // console.log(flagId,item.projectId)
            // return;
            this.flagId=flagId;
            this.curProjectId = item.projectId;
            if (flagId==2){
                this.setPPTDialogVisible=true;

            }else{
                const loading = this.$loading({
                    lock: true,
                    text: '疯狂制作中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                
                // if ((flagId==2 || flagId==3) && item.projectId==49){
                //     if (flagId==2){
                //         window.location = 'http://adeffect.fusewise-info.com/AdEffectService/UploadBCSFiles/Report/样例V1.pptx';
                //     }else{
                //         window.location = 'http://adeffect.fusewise-info.com/AdEffectService/UploadBCSFiles/Report/样例.pptx';
                //     }
                //     loading.close();
                    
                // }
                // else{
                    await ProjectExportById({userId: getToken(),flagId:this.flagId,projectId:item.projectId}).then(()=>{
                        loading.close();
                    })
                // }
            }
		},

        async onRemove(item) {
					// console.log(item)
                    this.$confirm('确认删除'+item.projectName+', 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(async () => {
                        
                        await DelProjectSmart({userId: getToken(), projectId: item.projectId});
                        
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.onQuery(0);
                    }).catch(() => {
                        // this.$message({
                        //     type: 'info',
                        //     message: '已取消删除'
                        // });          
                    });

						// await dealDeleteOperation({deletePromise:DelAdVersion({userId: getToken(), versionId: item.versionId})})
						
		},

        onResult(){
            this.result.frequency=0;
            this.result.cost=0;
            this.result.personCnt=0;
            this.selectedList.forEach(element => {
                this.result.frequency=this.result.frequency+element.frequency;
                this.result.cost=this.result.cost+element.cost;
                this.result.CPM=this.result.CPM+element.cpm;
            });
            this.result.CPM=parseFloat(this.result.CPM/this.selectedList.length).toFixed(2);
            this.result.cost=parseFloat(this.result.cost).toFixed(2);
            console.log('result',this.result)
            this.resultDialogVisible=true;
        },

        

        onCloseDetail(){
            this.onQuery(0);            
            this.$refs.filterDetailTable.clearFilter();
            this.setDetailDialogVisible = false;
        },

        closeDlg(item){
            this.uploadDialogVisible=item;
            this.verListDialogVisible = item;
            this.resultDialogVisible=item;
        },

        open(){
            // console.log('do open')
            this.$nextTick(() => {
            //  执行echarts方法
            
            })
        },
    }
};
</script>

<style scoped>

</style>